import axios from '../../node_modules/axios';

const isDev = process.env.NODE_ENV === 'development';
axios.defaults.baseURL = isDev ? 'https://localhost:7078/api/v1' : 'https://leadform-app.azurewebsites.net/api/v1';

window.progress = false;
window.success = false;

function updateState() {
  const $spinner = document.querySelector('.loader-wrapper');
  const $sendBtn = document.querySelector('#send');
  if (window.progress) {
    $spinner.classList.remove('is-hidden');
    $sendBtn.setAttribute('disabled','');
    (document.querySelectorAll('#lead input') || []).forEach(($input) => {
      $input.setAttribute('readonly', '');
    });  
  } else {    
    $spinner.classList.add('is-hidden');
    $sendBtn.removeAttribute('disabled');
    (document.querySelectorAll('#lead input') || []).forEach(($input) => {
      $input.removeAttribute('readonly');
    });  
  }

  const $formSucess = document.querySelector('#form-success');
  const $formSend = document.querySelector('#form-send');

  if (window.success) {
    $formSucess?.classList.remove('is-hidden');
    $formSend?.classList.add('is-hidden');
    (document.querySelectorAll('#lead input') || []).forEach(($input) => {
      $input.setAttribute('readonly', '');
    });
  } else {
    $formSucess?.classList.add('is-hidden');
    $formSend?.classList.remove('is-hidden');    
  }
}

function clearForm() {
  document.querySelectorAll("form#lead input").forEach(($input) => {
    $input.value = '';    
  });
  clearErrors();
}

function clearErrors() {
  (document.querySelectorAll('input.is-danger') || []).forEach(($input) => {
    $input.classList.remove('is-danger');
  });

  (document.querySelectorAll('.help.is-danger') || []).forEach(($input) => {
    $input.classList.add('is-hidden');
  });  
}

function clearElementErrors($el) {
  $el.classList.remove('is-danger');
  const dataIds = ['data-required-id', 'data-invalid-id', 'data-servererror-id'];
  dataIds.forEach(d => {
    const fieldId = $el.getAttribute(d);
    if (fieldId)  {
      document.querySelector(`#${fieldId}`)?.classList.add('is-hidden');
    }
  });
}

function showError($el, dataField) {
  $el.classList.add('is-danger'); 
  const fieldId = $el.getAttribute(dataField);
  if (fieldId) {
    document.querySelector(`#${fieldId}`)?.classList.remove('is-hidden');
  }
}

function validateRequired($el) {  
  const isValid = !!$el.value;
  if (!isValid) {
    showError($el, 'data-required-id');
  }
  return isValid;
}

function validateRegex($el, xpr) {
  const isValid = xpr.test($el.value);
  if (!isValid) {
    showError($el, 'data-invalid-id');
  }
  return isValid;
}

function validateName($el) {
  return validateRegex($el, /^[\p{L}\-\.\/ ]+$/gu);
}

function validateEmail($el) {
  return validateRegex($el, /(.+)@(.+){2,}\.(.+){2,}/);
}

function validate() {
  clearErrors();
  let isValid = true;
  const $emailInput = document.querySelector("input[name='email']");
  const $nameInput = document.querySelector("input[name='name']"); 
  const isEmailValid = validateRequired($emailInput) && validateEmail($emailInput); 
  const isNameValid = validateRequired($nameInput) && validateName($nameInput);
  isValid = isValid && isEmailValid && isNameValid;
  return isValid;
}

function showRemoteErrors({email,name}) {
  if (email) {
    const emailInput = document.querySelector("input[name='email']")
    showError(emailInput, 'data-servererror-id');
  }
  if (name) {
    const nameInput = document.querySelector("input[name='name']")
    showError(nameInput, 'data-servererror-id');
  }

  document.querySelector('#server-error').classList.remove('is-hidden');  
}

document.addEventListener('DOMContentLoaded', () => {
    // Functions to open and close a modal
    function openModal($el) {
      $el.classList.add('is-active');
      const html = document.documentElement;
      html.classList.add('is-clipped');
      window.success = false;
      clearForm();
      updateState();
    }
  
    function closeModal($el) {
      $el.classList.remove('is-active');
      const html = document.documentElement;
      html.classList.remove('is-clipped');
    }
  
    function closeAllModals() {
      (document.querySelectorAll('.modal') || []).forEach(($modal) => {
        closeModal($modal);
      });
    }
  
    // Add a click event on buttons to open a specific modal
    (document.querySelectorAll('.trigger-modal') || []).forEach(($trigger) => {
      const modal = $trigger.dataset.target;
      const $target = document.getElementById(modal);

      $trigger.addEventListener('click', () => {
        openModal($target);
      });
    });
  
    // Add a click event on various child elements to close the parent modal
    (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-close') || []).forEach(($close) => {
      const $target = $close.closest('.modal');
  
      $close.addEventListener('click', () => {
        if (!window.progress) {
          closeModal( $target);
        }
      });
    });
  
    // Add a keyboard event to close all modals
    document.addEventListener('keydown', (event) => {
      const e = event || window.event;
      if (e.key === 'Escape' && !window.progress) { // Escape key
        closeAllModals();
      }
    });

    const $form = document.querySelector("form#lead"); 
    $form.addEventListener('submit', e => {      
      e.preventDefault();      
      const isValid = validate();
      
      if (isValid) {
        window.progress = true;      

        const email = document.querySelector("input[name='email']").value;
        const name = document.querySelector("input[name='name']").value;
        const site = window.location.hostname;

        axios.post('/lead', {email, name, site })
        .then(function (response) {
          window.progress = false;     
          window.success = true;     
          updateState(); 
        })
        .catch(function (error) {
          window.progress = false;
          console.log(error);
          const response = error.response;
          let hasEmailError = false, hasNameError = false;
          if (response?.status == 400) {
            const fieldErrors = response.data.errors;
            hasEmailError = !!(fieldErrors["Email"] || []).find(o => true);
            hasNameError = !!(fieldErrors["Name"] || []).find(o => true);
          }

          showRemoteErrors({
            email: hasEmailError,
            name:  hasNameError
          });

          updateState(); 
        });
      }

      updateState();
    });   

    document.querySelector('#lead input[name="email"]').addEventListener('blur', () => {
      const $emailInput = document.querySelector("input[name='email']");      
      clearElementErrors($emailInput);
      const isValid = validateRequired($emailInput) && validateEmail($emailInput); 
    });

    document.querySelector('#lead input[name="name"]').addEventListener('blur', () => {
      const $nameInput = document.querySelector("input[name='name']");
      clearElementErrors($nameInput);
      const isValid = validateRequired($nameInput) && validateName($nameInput); 
    });
});